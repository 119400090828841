import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import { Col, Container, Row } from "react-bootstrap";

const IndexPage = () => {
	// useEffect(() => {
	// 	if (typeof window !== "undefined") {
	// 		window.location.href = "/admin/home";
	// 	}
	// }, []);

	return (
		<>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo title="Security" language="en" noindex nofollow />

			<div style={{ background: "#F8F8F8" }}>
				<Navigation />
				<section
					className="bg-white py-5 py-lg-7"
					style={{ minHeight: "95vh" }}
				>
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h1 className="display-5 mb-4">
									Unexpected Login Attempt Detected
								</h1>
								<p>Security is our top priority.</p>
								<p>
									{" "}
									You've reached this page because your login attempt was made
									from an unrecognized IP address or email domain. This is a
									security measure to protect your account and our system.
								</p>
								<p>
									{" "}
									We understand that this might be inconvenient, but your
									digital safety is crucial to us. If you believe this is an
									error, or if you're having trouble accessing your account,
									we're here to help.
								</p>
								<p>
									{" "}
									To resolve this issue, please verify your login credentials
									and ensure you're using the correct email domain. If the
									problem persists, don't hesitate to reach out for assistance.
								</p>
								<p>
									{" "}
									Your secure and seamless experience is important to us.
									Contact our support team, and we'll work together to resolve
									any login issues you're facing.
								</p>
								<p>Thank you for your understanding and cooperation.</p>
								<p>The Richmond Events Team</p>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
		</>
	);
};

export default IndexPage;
